import styled from '@emotion/styled';

export const Card = styled('div')(() => ({
  gap: '1.6rem',
  width: '100%',
  display: 'flex',
  padding: '2.4rem',
  background: '#fff',
  position: 'relative',
  willChange: 'padding',
  borderRadius: '2.4rem',
  flexDirection: 'column',
  transition: 'padding .2s ease',
}));
