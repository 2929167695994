'use client';

import React from 'react';
import { BgMask } from './bg-mask';
import NextImage from 'next/image';
import bg from '@shared/icons/defaultBackground.png';
import { BgImageContainer } from './bg-image-container';
import { selectPreviewMode, useSelector } from '@shared/redux';
import { defaultEndColor, defaultStartColor } from '@shared/constants';

const imageStyle: React.CSSProperties = {
  objectFit: 'cover',
  objectPosition: 'center',
};

export const Background: React.FC<{
  theme?: any;
  isPreview?: boolean;
}> = ({ theme, isPreview }) => {
  const [quality, setQuality] = React.useState(10);
  const previewMode = useSelector(selectPreviewMode);
  const [bgImage, setBgImage] = React.useState(theme?.bg_image || bg);

  React.useEffect(() => {
    setQuality(10);
  }, [theme?.bg_image]);

  const onLoad = React.useCallback(() => setQuality(100), []);
  const onError = React.useCallback(() => setBgImage(bg), []);

  const isMobile = previewMode === 'mobile';

  const paletteColors = theme?.shuffled_palette?.split(',');

  let endColor = defaultEndColor;
  let startColor = theme?.primary_color || defaultStartColor;

  if (Array.isArray(paletteColors) && paletteColors[0]) {
    endColor = paletteColors[0];
  }

  const background = `linear-gradient(113deg, ${endColor} 0%, ${startColor} 50%)`;

  return (
    <>
      <BgImageContainer isMobile={isMobile} isPreview={isPreview}>
        <NextImage
          fill={true}
          src={bgImage}
          onLoad={onLoad}
          onError={onError}
          quality={quality}
          style={imageStyle}
          alt="Page Background"
        />
      </BgImageContainer>
      <BgMask
        isMobile={isMobile}
        isPreview={isPreview}
        background={background}
      />
    </>
  );
};
