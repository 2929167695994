import React from 'react';
import { Flex, Tag } from 'antd';
import { Box } from '@shared/ui/box';
import { Builder } from '@/lib/types/builder';
import { Categories } from '@/lib/types/categories';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { fontVariants } from '@shared/constants/fontVariants';

export const MediakitCategories: React.FC<{
  categories: Categories.List;
  tagStyles?: React.CSSProperties;
  mediakit: Builder.Mediakit.Mediakit;
}> = ({ mediakit, tagStyles, categories }) => {
  const matches = useBreakpoint(768);
  const [match, setMatch] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    setMatch(matches);
  }, [matches]);

  const cats = categories.reduce<Record<string, Categories.Category>>(
    (acc, curr) => {
      acc[curr.value] = curr;

      return acc;
    },
    {},
  );

  return (
    !!mediakit.categories && (
      <Box
        boxStyles={{
          gap: '.8rem',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {mediakit.categories.length > 1 && match ? (
          <>
            {mediakit.categories.map((category, index) => {
              if (index > currentIndex) {
                return;
              }
              return (
                <Tag
                  key={index}
                  style={tagStyles}
                  className={fontVariants.opt3}
                >
                  {cats[category]?.label || category}
                </Tag>
              );
            })}
            {currentIndex !== Infinity && (
              <Tag
                className={fontVariants.opt3}
                onClick={() => setCurrentIndex(+Infinity)}
                style={Object.assign({}, tagStyles, {
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.88)',
                })}
              >
                and {mediakit.categories.length - 1} others
              </Tag>
            )}
          </>
        ) : (
          <>
            {mediakit.categories.map((category, index) => (
              <Tag key={index} style={tagStyles} className={fontVariants.opt3}>
                {cats[category]?.label || category}
              </Tag>
            ))}
          </>
        )}
      </Box>
    )
  );
};
