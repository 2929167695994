import styled from '@emotion/styled';
import { breakpoints, builderBreakpoint } from '@shared/theme';

export const ProfileCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: { isMobile?: boolean }) => {
  let profileCardNewStyles = isMobile
    ? {
        top: 'unset',
        width: '100%',
        marginInline: 'auto',
      }
    : {};

  return {
    top: 104,
    width: 364,
    zIndex: 92,
    marginTop: 80,
    display: 'flex',
    marginRight: 24,
    position: 'sticky',
    textAlign: 'center',
    height: 'fit-content',
    flexDirection: 'column',
    [`@media (max-width: ${builderBreakpoint})`]: {
      top: 'unset',
      marginInline: 'auto',
    },
    [`@media (max-width: ${breakpoints.xs})`]: {
      width: '100%',
    },
    ...profileCardNewStyles,
  };
});
