import { Typography } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const ProfileNameTitle = styled(Typography.Title)(() => ({
  [`h3&.${prefixCls}-typography`]: {
    marginBottom: 0,
    padding: '.4rem',
    textAlign: 'center',
  },
}));
