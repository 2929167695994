'use client';

import React from 'react';
import styled from '@emotion/styled';
import { Button, Flex, Layout } from 'antd';
import { useSearchParams } from 'next/navigation';
import { AppRoutes } from '@shared/constants/appRoutes';
import { shadow } from '@shared/theme';
import useBreakpoint from '@/lib/hooks/useBreakpoint';

const logo = (
  <svg
    width="32"
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      d="M23.1222 16.3914C23.1222 20.208 19.9122 23.3395 16 23.3395C12.0878 23.3395 8.87775 20.208 8.87775 16.3914V15.6086C8.87775 11.792 12.0878 8.66055 16 8.66055H23.1222V16.3914ZM23.1222 0H16C7.17242 0 0 6.99694 0 15.6086V16.3914C0 25.0031 7.17242 32 16 32C24.8276 32 32 25.0031 32 16.3914V8.66055C32 3.91437 28.0376 0 23.1222 0Z"
    />
  </svg>
);

const logoDark = (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1222 16.3914C23.1222 20.208 19.9122 23.3395 16 23.3395C12.0878 23.3395 8.87775 20.208 8.87775 16.3914V15.6086C8.87775 11.792 12.0878 8.66055 16 8.66055H23.1222V16.3914ZM23.1222 0H16C7.17242 0 0 6.99694 0 15.6086V16.3914C0 25.0031 7.17242 32 16 32C24.8276 32 32 25.0031 32 16.3914V8.66055C32 3.91437 28.0376 0 23.1222 0Z"
      fill="black"
    />
  </svg>
);

const StyledHeader = styled(Layout.Header)(() => ({
  top: 0,
  zIndex: 999,
  display: 'flex',
  position: 'sticky',
  alignItems: 'center',
  paddingInline: '2.4rem',
  justifyContent: 'space-between',
  transition: 'background 0.3s, box-shadow 0.3s',
  background: 'linear-gradient(rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0) 100%)',
  '@media (max-width: 768px)': {
    '&.scrolled': {
      boxShadow: shadow,
      background: '#fff',
    },
  },
}));

const HeaderLink = styled('a')(() => ({
  display: 'inline-flex',
}));

export const MkitHeader: React.FC<{ handle: string; isPreview?: boolean }> = ({
  handle,
  isPreview,
}) => {
  const is768 = useBreakpoint(768);
  const [currentLogo, setCurrentLogo] = React.useState(logo);

  React.useEffect(() => {
    if (!is768) {
      return;
    }

    const handleScroll = () => {
      const header = document.querySelector('header');

      if (header) {
        const scrollPosition = window.scrollY;

        if (scrollPosition > 0) {
          header.classList.add('scrolled');

          setCurrentLogo(logoDark);
        } else {
          header.classList.remove('scrolled');

          setCurrentLogo(logo);
        }
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [is768, setCurrentLogo]);

  const searchString = useSearchParams().toString();

  let loginHref = `${AppRoutes.login}?utm_source=mkit_share_get_started&utm_content=${handle}`;
  let registerHref = `${AppRoutes.registerStep1}?utm_source=mkit_share_get_started&utm_content=${handle}`;

  if (searchString) {
    loginHref = `${loginHref}&${searchString}`;
    registerHref = `${registerHref}&${searchString}`;
  }

  return !isPreview ? (
    <StyledHeader>
      <HeaderLink href={loginHref}>{currentLogo}</HeaderLink>
      <Flex gap={8}>
        <HeaderLink href={registerHref}>
          <Button size="large">Sign Up</Button>
        </HeaderLink>
        <HeaderLink href={loginHref}>
          <Button size="large">Sign In</Button>
        </HeaderLink>
      </Flex>
    </StyledHeader>
  ) : null;
};
