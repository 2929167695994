import styled from '@emotion/styled';
import { breakpoints } from '@shared/theme';

export const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: { isMobile?: boolean }) => ({
  display: 'flex',
  '& > div': { width: '100%' },
  padding: isMobile ? '1.6rem' : '2.4rem',
  [`@media (max-width: ${breakpoints.lg})`]: {
    padding: '1.6rem',
  },
}));
