import styled from '@emotion/styled';
import { breakpoints, builderBreakpoint } from '@shared/theme';

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: { isMobile?: boolean }) => ({
  zIndex: 10,
  width: '100%',
  display: 'flex',
  paddingBottom: '4rem',
  flexDirection: isMobile ? 'column' : 'row',
  justifyContent: isMobile ? 'flex-start' : 'center',
  [`@media (max-width: ${builderBreakpoint})`]: {
    paddingBottom: '10rem',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  [`@media (max-width: ${breakpoints.lg})`]: {
    paddingBottom: '10rem',
  },
}));
