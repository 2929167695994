'use client';

import React from 'react';
import { Button } from 'antd';
import { Box } from '@shared/ui/box';
import styled from '@emotion/styled';
import { Builder } from '@/lib/types/builder';
import { SocialLinksModal } from './social-links-modal';
import { fontVariants } from '@shared/constants/fontVariants';
import { socialPlatformsIcons } from '@shared/ui/social-icons';
import { SocialLinksContainer } from './social-links-container';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Link = styled('a')(() => ({
  width: 32,
  height: 32,
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  color: 'rgba(0, 0, 0, 0.45)',
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.65)',
  },
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})(({ textColor }: { textColor: string }) => ({
  color: textColor,
  border: `1px solid ${textColor}`,
}));

const iconProps: Partial<CustomIconComponentProps> = {
  style: { width: 20, height: 20 },
};

export const SocialLinks: React.FC<{
  textColor: string;
  socialLinks?: Builder.CurrentMediakit.SocialLink[];
}> = ({ socialLinks, textColor }) => {
  const [open, setOpen] = React.useState(false);

  const onOpen = () => setOpen(true);

  const onCancel = () => setOpen(false);

  if (!socialLinks?.length) return null;

  return (
    <Box>
      <SocialLinksContainer>
        {socialLinks.map((field, index) =>
          index > 3 ? null : (
            <Link
              rel="nofollow"
              target="_blank"
              href={field.value}
              key={field.platform}
              style={{ color: '#000000' }}
            >
              {socialPlatformsIcons[field.platform].dark(iconProps)}
            </Link>
          ),
        )}
        {socialLinks.length > 4 && (
          <StyledButton
            size="small"
            type="text"
            shape="round"
            onClick={onOpen}
            textColor={textColor}
            className={fontVariants.opt3}
          >
            +{socialLinks.length - 4}
          </StyledButton>
        )}
      </SocialLinksContainer>
      <SocialLinksModal
        open={open}
        onCancel={onCancel}
        socialLinks={socialLinks}
      />
    </Box>
  );
};
