'use client';

import React from 'react';
import { Layout } from 'antd';
import { Content } from './ui/content';
import { Profile } from './ui/profile';
import { Container } from './ui/container';
import { Background } from './ui/background';
import { Builder } from '@/lib/types/builder';
import { MkitHeader } from './ui/mkit-header';
import { GlobalStyles } from './ui/global-styles';
import { layoutStyles } from '@/lib/styles/common';
import { Categories } from '@/lib/types/categories';
import { Layout as ILayout } from 'react-grid-layout';
import { CreateYourMediaKit } from '@widgets/create-your-media-kit';
import { GridLayoutServer } from '@widgets/mediakit/GridLayout/server';
import { LayoutBreakpoints } from '@shared/constants/layoutBreakpoints';
import { CreateMediaKitContainer } from './ui/create-media-kit-container';
import {
  onResetTheme,
  onUpdateTheme,
  selectPreviewMode,
  useDispatch,
  useSelector,
} from '@shared/redux';

export function MediaKitServer({
  user,
  theme,
  visits,
  layouts,
  mediakit,
  isPreview,
  extraData,
  categories,
}: {
  theme: any;
  visits?: any;
  layouts: {
    [LayoutBreakpoints.sm]: ILayout[];
    [LayoutBreakpoints.xxs]: ILayout[];
  };
  isPreview?: boolean;
  user?: { vm_verify: number };
  categories: Categories.List;
  extraData: Builder.ExtraData;
  mediakit: Builder.Mediakit.Mediakit;
}) {
  const dispatch = useDispatch();
  const previewMode = useSelector(selectPreviewMode);

  const [opacity, setOpacity] = React.useState<number>(1);

  React.useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 400);
  }, []);

  const styles = Object.assign({}, layoutStyles, {
    opacity,
  });

  React.useEffect(() => {
    // dispatch(onUpdateTheme(theme));
  }, [theme, dispatch]);

  React.useEffect(() => {
    return function () {
      // dispatch(onResetTheme());
    };
  }, [dispatch]);

  const isMobile = previewMode === 'mobile' && isPreview;

  return (
    <Layout style={styles}>
      <GlobalStyles fonts={theme?.fonts} />

      <Background theme={theme} isPreview={isPreview} />

      <React.Suspense fallback="Loading">
        <MkitHeader isPreview={isPreview} handle={mediakit.handle} />
      </React.Suspense>

      <Content isMobile={isMobile}>
        <Container isMobile={isMobile}>
          <Profile
            user={user}
            theme={theme}
            visits={visits}
            mediakit={mediakit}
            isPreview={isPreview}
            categories={categories}
          />
          <GridLayoutServer
            theme={theme}
            layouts={layouts}
            isPreview={isPreview}
            extraData={extraData}
            handle={mediakit.handle}
          />
          <CreateMediaKitContainer>
            <CreateYourMediaKit
              theme={theme}
              visits={visits}
              username={mediakit.handle}
            />
          </CreateMediaKitContainer>
        </Container>
      </Content>
    </Layout>
  );
}
