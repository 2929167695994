import styled from '@emotion/styled';
import { breakpoints, builderBreakpoint } from '@shared/theme';

export const CreateMediaKitContainer = styled('div')(() => ({
  width: 364,
  display: 'none',
  flexDirection: 'column',
  [`@media (max-width: ${builderBreakpoint})`]: {
    display: 'flex',
    marginInline: 'auto',
  },
  [`@media (max-width: ${breakpoints.xs})`]: {
    width: '100%',
    display: 'flex',
  },
}));

export const CreateMediaKitContainerDesktop = styled('div')(
  ({ isPreview }: { isPreview?: boolean }) => ({
    width: '100%',
    marginTop: '2.4rem',
    display: isPreview ? 'none' : 'flex',
    [`@media (max-width: ${builderBreakpoint})`]: {
      display: 'none',
    },
  }),
);
