import React from 'react';
import styled from '@emotion/styled';
import { Modal, Typography } from 'antd';
import { Builder } from '@/lib/types/builder';
import { RightOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal/interface';
import { socialPlatformsIcons } from '@shared/ui/social-icons';
import { SocialLinksModalBody } from './social-links-modal-body';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const modalStyle: React.CSSProperties = {
  top: 20,
};

const modalStyles: ModalProps['styles'] = {
  body: {
    padding: 0,
    overflowY: 'auto',
    paddingRight: '2.4rem',
    maxHeight: 'calc(100vh - 200px)',
  },
  content: { paddingRight: 0 },
};

const iconProps: Partial<CustomIconComponentProps> = {
  style: { width: 32, height: 32 },
};

const Link = styled('a')(() => ({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const LabelWrapper = styled('div')(() => ({
  gap: '.8rem',
  width: '100%',
  maxWidth: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const SocialLinksModal: React.FC<{
  open: boolean;
  onCancel: () => void;
  socialLinks: Builder.CurrentMediakit.SocialLink[];
}> = ({ open, onCancel, socialLinks }) => (
  <Modal
    closable
    width={380}
    open={open}
    footer={null}
    destroyOnClose
    style={modalStyle}
    title="Social Links"
    onCancel={onCancel}
    styles={modalStyles}
  >
    <SocialLinksModalBody>
      {socialLinks.map((field, index) => (
        <Link key={index} rel="nofollow" target="_blank" href={field.value}>
          <LabelWrapper>
            <Typography.Text>
              {socialPlatformsIcons[field.platform].dark(iconProps)}
            </Typography.Text>
            <Typography.Text ellipsis>{field.value}</Typography.Text>
          </LabelWrapper>
          <Typography.Text>
            <RightOutlined />
          </Typography.Text>
        </Link>
      ))}
    </SocialLinksModalBody>
  </Modal>
);
