import React from 'react';
import styled from '@emotion/styled';
import { Text } from '@shared/ui/typography';
import { fontVariants } from '@shared/constants/fontVariants';

const Container = styled('div')(() => ({
  width: '100%',
  gap: '1.3rem',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    maxWidth: '50%',
  },
}));

const InputsWrapper = styled('div')(() => ({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
  width: 'max-content',
}));

const svgStyles: React.CSSProperties = { minHeight: 15, minWidth: 15 };

export const CountryHandle: React.FC<{
  fill: string;
  handle: string;
  location?: string;
}> = ({ fill, handle, location }) => {
  return (
    <Container>
      <InputsWrapper>
        <svg
          width="15"
          height="14"
          fill="none"
          style={svgStyles}
          viewBox="0 0 15 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icon/Logo">
            <path
              id="Logo"
              fill={fill}
              d="M10.9276 7.17125C10.9276 8.84098 9.52324 10.211 7.81165 10.211C6.10005 10.211 4.69566 8.84098 4.69566 7.17125V6.82875C4.69566 5.15902 6.10005 3.78899 7.81165 3.78899H10.9276V7.17125ZM10.9276 0H7.81165C3.94958 0 0.811646 3.06116 0.811646 6.82875V7.17125C0.811646 10.9388 3.94958 14 7.81165 14C11.6737 14 14.8116 10.9388 14.8116 7.17125V3.78899C14.8116 1.71254 13.0781 0 10.9276 0Z"
            />
          </g>
        </svg>
        <Text className={fontVariants.opt3} ellipsis={{ tooltip: true }}>
          {handle}
        </Text>
      </InputsWrapper>
      {!!location && (
        <InputsWrapper>
          <svg
            width="15"
            fill="none"
            height="16"
            style={svgStyles}
            viewBox="0 0 15 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill={fill}
              d="M7.49992 4.67468C6.96233 4.67468 6.4571 4.88324 6.07593 5.26441C5.88863 5.45118 5.7401 5.67314 5.63889 5.91752C5.53768 6.1619 5.48579 6.42388 5.48621 6.68839C5.48621 7.22598 5.69657 7.73121 6.07593 8.11237C6.2627 8.29968 6.48466 8.44821 6.72904 8.54941C6.97342 8.65062 7.23541 8.70251 7.49992 8.7021C8.0375 8.7021 8.54273 8.49174 8.9239 8.11237C9.30506 7.73121 9.51362 7.22598 9.51362 6.68839C9.51362 6.1508 9.30506 5.64558 8.9239 5.26441C8.54273 4.88324 8.0375 4.67468 7.49992 4.67468ZM13.6597 3.99326C13.3212 3.21273 12.8332 2.50603 12.2231 1.91302C11.6111 1.31466 10.8896 0.83975 10.098 0.514215C9.2745 0.172604 8.40069 0 7.49992 0C6.59914 0 5.72533 0.172604 4.90187 0.512417C4.10538 0.841443 3.39159 1.31251 2.77669 1.91123C2.16704 2.50456 1.67906 3.21118 1.34012 3.99146C0.989521 4.80054 0.811523 5.65996 0.811523 6.54456C0.811523 7.81391 1.11538 9.07787 1.7123 10.2969C2.19235 11.2768 2.86299 12.2315 3.70803 13.1395C5.15179 14.6893 6.66566 15.6368 7.09538 15.8903C7.21735 15.9624 7.35646 16.0002 7.49812 16C7.63836 16 7.7768 15.964 7.90086 15.8903C8.33057 15.6368 9.84445 14.6893 11.2882 13.1395C12.1332 12.2333 12.8039 11.2768 13.2839 10.2969C13.8845 9.07967 14.1883 7.81751 14.1883 6.54635C14.1883 5.66176 14.0103 4.80234 13.6597 3.99326ZM7.49992 9.85279C5.7523 9.85279 4.33551 8.436 4.33551 6.68839C4.33551 4.94078 5.7523 3.52399 7.49992 3.52399C9.24753 3.52399 10.6643 4.94078 10.6643 6.68839C10.6643 8.436 9.24753 9.85279 7.49992 9.85279Z"
            />
          </svg>
          <Text className={fontVariants.opt3} ellipsis={{ tooltip: true }}>
            {location}
          </Text>
        </InputsWrapper>
      )}
    </Container>
  );
};
