import styled from '@emotion/styled';
import { previewTopDiff } from '@shared/theme';

export const BgMask = styled('div', {
  shouldForwardProp: (prop) =>
    !['isMobile', 'isPreview', 'background'].includes(prop),
})(({
  isMobile,
  isPreview,
  background,
}: {
  isMobile?: boolean;
  isPreview?: boolean;
  background: string;
}) => {
  const previewStyles = !isPreview
    ? {}
    : {
        borderRadius: 12,
        overflow: 'hidden',
        top: previewTopDiff,
        height: 'calc(100% - 144px)',
        left: isMobile ? 'unset' : 52,
        minHeight: 'calc(100vh - 144px)',
        marginInline: isMobile ? 'auto' : '0',
        width: isMobile ? 370 : 'calc(100% - 104px)',
        minWidth: isMobile ? 370 : 'calc(100vw - 104px)',
        [`@media (max-width: 425px)`]: {
          left: 0,
          width: '100%',
          minWidth: '100%',
        },
      };

  return {
    top: 0,
    left: 0,
    zIndex: 0,
    background,
    width: '100%',
    opacity: 0.32,
    height: '100%',
    position: 'fixed',
    minWidth: '100vw',
    minHeight: '100vh',
    ...previewStyles,
  };
});
