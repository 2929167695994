import React from 'react';
import { Tooltip } from 'antd';
import { verifiedIcon } from '@shared/ui/Icons';
import { ProfileNameTitle } from './profile-name-title';
import { fontVariants } from '@shared/constants/fontVariants';
import { ProfileNameContainer } from './profile-name-container';
import { VerificationStatuses } from '@shared/constants/verificationStatuses';

export const ProfileName: React.FC<{
  name: string;
  user?: { vm_verify: number };
}> = ({ name, user }) => {
  const isVerified = user?.vm_verify === VerificationStatuses.VERIFY_SUCCESS;

  return (
    <ProfileNameContainer>
      <ProfileNameTitle level={3} className={fontVariants.opt1}>
        {name}
      </ProfileNameTitle>
      {isVerified && <Tooltip title="Verified Profile">{verifiedIcon}</Tooltip>}
    </ProfileNameContainer>
  );
};
